// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "node_modules/@angular/material/theming";

// SCSS Variables
@import "var";

// Styles / CSS Variables
@import "libs/@vex/styles/partials/styles/style-dark";
@import "libs/@vex/styles/partials/styles/style-light";
@import "libs/@vex/styles/partials/styles/style-default";

// Mixins
@import "libs/@vex/styles/partials/mixins";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
 @include mat-core();
 
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* @include angular-material-theme($vex-theme);
 */

@include mat.all-component-typographies($configTopography);
 @include mat.core();
@include mat.all-component-themes($vex-theme);
// Partials
@import "libs/@vex/styles/partials/horizontal";
@import "libs/@vex/styles/partials/vertical";
@import "libs/@vex/styles/partials/print";
@import "libs/@vex/styles/partials/typography";
@import "libs/@vex/styles/partials/overrides";
@import "libs/@vex/styles/partials/scrollbar";
@import "libs/@vex/styles/partials/plugins/angular-material";
@import "libs/@vex/styles/partials/plugins/apexcharts";

// Layouts
@import "libs/@vex/styles/partials/layouts/layout-apollo";
@import "libs/@vex/styles/partials/layouts/layout-ares";
@import "libs/@vex/styles/partials/layouts/layout-hermes";
@import "libs/@vex/styles/partials/layouts/layout-ikaros";
@import "libs/@vex/styles/partials/layouts/layout-zeus";

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  @include mat-typography-level-to-styles($config, body-1);
  @apply text-black leading-normal antialiased;
  font-feature-settings: 'kern';
}

div {
  box-sizing: border-box;
}

