/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* Importing PrimeNG CSS. */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

/* Importing Bootstrap SCSS file. */
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "libs/@vex/styles/core";

.sucess {
  color: green;
}

.danger {
  color: red;
}
// Add your custom styles below
mat-error {
  font-size: 90%;                  
  line-height: 1.125;
  color: red;
}
mat-hint {
  font-size: 80%;                  
  color: rgba(0, 0, 0, 0.75);      
  font-weight: 400;                
}

